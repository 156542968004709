'use client';

import type { PropsWithChildren } from 'react';
import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material';

import { Cms_Tenant } from '@lib/services/cms';
import { Tenant_ColorPalette } from '@lib/services/tenant';

interface MuiThemeProps extends PropsWithChildren {
  tenant?: Cms_Tenant;
  defaultColors: Tenant_ColorPalette;
}

const MuiTheme: React.FC<MuiThemeProps> = ({ children, tenant, defaultColors }) => {
  const primary = tenant?.colorPrimary?.hex ? `#${tenant.colorPrimary.hex}` : defaultColors.primary;

  const theme = createTheme({
    palette: {
      primary: { main: primary },
      error: { main: defaultColors.error }
    },
    typography: {
      fontFamily: 'var(--f-sans)'
    },
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: { width: '100%' }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: '#FFFFFF'
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
            background: 'rgba(255,255,255,0.08)',
            '&:hover:not(.Mui-error):not(.Mui-disabled) fieldset': {
              borderColor: `${primary} !important`
            }
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            fontSize: '0.875rem'
            //  marginTop: '0.5rem'
          }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            background: '#000511'
          }
        }
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            color: '#FFFFFF',
            fontSize: '1rem',
            '&.Mui-selected': {
              background: 'var(--c-primary-a20)', //  tenant.style.colors.prim
              color: 'var(--c-primary)'
            },
            '&:hover': {
              background: 'var(--c-primary-a10)',
              color: 'var(--c-primary)'
            }
          }
        }
      }
    }
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export { MuiTheme };
export type { MuiThemeProps };
